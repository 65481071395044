<script setup lang="ts">
import * as toastr from 'toastr';
import { defineProps } from 'vue';

type MessageType = 'notice' | 'alert';
type Props = {
  messageType: MessageType;
  message: string;
}

const props = defineProps<Props>();

toastr.options.closeButton = false;
toastr.options.timeOut = 50000;
toastr.options.positionClass = 'toast-under-header-top-right';

switch (props.messageType) {
  case 'notice':
    toastr.success(props.message);
    break;
  case 'alert':
    toastr.error(props.message);
    break;
  default:
    break;
}
</script>
